import { useMemo, useState } from "react";
import {
    DATE_SELECT_OPTIONS,
    DISALLOWED_NUMBER_SYMBOLS,
    INIT_DESC_PWA,
    TAGS_PWA,
    WINDOW_INSTALL_OPTIONS,
} from "../../constants/pwa";
import ImageUploader from "../../components/UI/ImageUploader";
import ScreensBlock from "./ScreensBlock";
import TextareaWithLabel from "../../components/UI/TextareaWithLabel";
import InputWithLabel from "../../components/UI/InputWithLabel";
import SelectWithLabel from "../../components/UI/SelectWithLabel";
import { formatRating, sortLanguages } from "../../util/util";
import LanguagesList from "./LanguagesList";
import Accordion from "../../components/UI/Accordion";
import { ImageType } from "../../common/commonTypes";
import ImageWithModal from "../../components/UI/ImageWithModal";
import CreatableSelectWithLabel from "../../components/UI/CreatableSelectWithLabel";

const Description = ({ data, updateData, languagesList }) => {
    const [isShowLanguagesModal, setIsShowLanguageModal] = useState(false);
    const [activeLng, setActiveLng] = useState(
        data?.descriptions ? Object.keys(data.descriptions)[0] : ""
    );

    const addLanguage = ({ lngFrom, lngTo }) => {
        const descriptionsCopy = { ...data.descriptions };
        descriptionsCopy[lngTo] = JSON.parse(
            JSON.stringify(lngFrom ? data.descriptions[lngFrom] : INIT_DESC_PWA)
        );
        updateData("descriptions", descriptionsCopy);
        setIsShowLanguageModal(false);
        setActiveLng(lngTo);
    };

    const deleteLng = (lng) => {
        const confirmDelete = window.confirm(
            `Вы действительно хотите удалить ${languagesList[lng] || lng}?`
        );

        if (!confirmDelete) return;
        const descriptionsCopy = { ...data.descriptions };
        delete descriptionsCopy[lng];

        updateData("descriptions", descriptionsCopy);

        if (lng === data.defaultLang) {
            updateData("defaultLang", "");
        }
        if (activeLng === lng) {
            setTimeout(() => {
                setActiveLng(Object.keys(data.descriptions)?.[0] || "");
            }, 0);
        }
    };

    const updateLngContent = (key, value) => {
        const descriptionsCopy = JSON.parse(JSON.stringify(data.descriptions));
        descriptionsCopy[activeLng][key] = value;
        updateData("descriptions", descriptionsCopy);
    };

    const defaultLanguages = useMemo(() => {
        const languages = [];

        if (!languagesList) return languages;

        Object.keys(languagesList).forEach((lng) => {
            if (data?.descriptions?.[lng]) {
                languages.push({
                    label: languagesList[lng],
                    value: lng,
                });
            }
        });

        return sortLanguages(languages);
    }, [data?.descriptions, languagesList]);

    const content = data?.descriptions?.[activeLng];

    return (
        <div className="description-pwa" id="description-pwa">
            <div className="four-fields" style={{ margin: "0 0 16px" }}>
                <SelectWithLabel
                    label="Язык по умолчанию"
                    options={defaultLanguages}
                    value={data.defaultLang}
                    fieldName="defaultLang"
                    onChange={updateData}
                />
            </div>
            <div>
                <LanguagesList
                    data={data?.descriptions}
                    addLanguage={addLanguage}
                    deleteLng={deleteLng}
                    activeLng={activeLng}
                    setActiveLng={setActiveLng}
                    isShowModal={isShowLanguagesModal}
                    setIsShowModal={setIsShowLanguageModal}
                    languagesList={languagesList}
                />
            </div>

            {content && (
                <div className="desc-lang">
                    <div className="four-fields">
                        <InputWithLabel
                            label="Название PWA"
                            value={content.title}
                            fieldName="title"
                            onChange={updateLngContent}
                        />
                        <InputWithLabel
                            label="Имя разработчика"
                            value={content.developer}
                            fieldName="developer"
                            onChange={updateLngContent}
                        />
                    </div>
                    {/* <CheckboxWithLabel
                        label={`Выводить иконку "верифицирован"`}
                        isChecked={content.showIconVerification}
                        fieldName="showIconVerification"
                        onChange={updateLngContent}
                    /> TEMP COMMIT */}

                    <div className="filed-block">
                        {content.icon ? (
                            <ImageWithModal
                                src={`/images/${content.icon}`}
                                onDelete={() => updateLngContent("icon", "")}
                            />
                        ) : (
                            <ImageUploader
                                label="Иконка"
                                onChange={updateLngContent}
                                fieldName="icon"
                                imageType={ImageType.ICON}
                            />
                        )}
                    </div>

                    <ScreensBlock
                        screens={content.screens}
                        updateLngContent={updateLngContent}
                    />
                    <div className="filed-block">
                        <TextareaWithLabel
                            label="Описание"
                            minHeight="150px"
                            value={content.desc}
                            fieldName="desc"
                            onChange={updateLngContent}
                        />
                    </div>
                    <div className="four-fields">
                        <InputWithLabel
                            label="Ссылка на видео"
                            value={content.videoUrl}
                            fieldName="videoUrl"
                            onChange={updateLngContent}
                        />
                    </div>
                    <div className="filed-block"></div>
                    <Accordion title="Oкно установки" className="filed-block">
                        <div className="four-fields">
                            <SelectWithLabel
                                options={WINDOW_INSTALL_OPTIONS}
                                value={content.windowInstallType}
                                onChange={updateLngContent}
                                fieldName="windowInstallType"
                                label="Окно установки"
                            />
                            {content.windowInstallType === "withScreens" && (
                                <TextareaWithLabel
                                    value={content.windowInstallDesc}
                                    onChange={updateLngContent}
                                    fieldName="windowInstallDesc"
                                    label="Описание над скриншотом"
                                />
                            )}
                        </div>
                    </Accordion>
                    <Accordion title="Что нового" className="filed-block">
                        <div className="four-fields">
                            <SelectWithLabel
                                options={DATE_SELECT_OPTIONS}
                                value={data.lastUpdated}
                                onChange={updateData}
                                label="Последнее обновление"
                                fieldName="lastUpdated"
                            />
                            <TextareaWithLabel
                                label="Что нового"
                                value={content.news}
                                fieldName="news"
                                onChange={updateLngContent}
                            />
                        </div>
                    </Accordion>
                </div>
            )}
            <div
                className="static-desc"
                style={{
                    display:
                        data?.descriptions &&
                        Object.keys(data?.descriptions).length
                            ? "block"
                            : "none",
                }}
            >
                <div className="four-fields">
                    <InputWithLabel
                        label="Возраст"
                        value={data.age}
                        type="number"
                        fieldName="age"
                        onChange={updateData}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                    <InputWithLabel
                        label="Загрузок"
                        type="number"
                        value={data.download}
                        fieldName="download"
                        onChange={updateData}
                        min={1}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                    <InputWithLabel
                        label="Отзывов"
                        value={data.reviews}
                        type="number"
                        fieldName="reviews"
                        onChange={updateData}
                        min={1}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                    <InputWithLabel
                        label="Рейтинг"
                        type="number"
                        value={data.rating}
                        onBlur={() =>
                            updateData("rating", formatRating(+data.rating))
                        }
                        fieldName="rating"
                        onChange={updateData}
                        min={1}
                        max={5}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                </div>

                <div className="four-fields">
                    <InputWithLabel
                        label="Размер файла, Мб"
                        type="number"
                        value={data.btnDownSize}
                        fieldName="btnDownSize"
                        onChange={updateData}
                        min={1}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                    <InputWithLabel
                        label="Время загрузки, c"
                        value={data.btnDownTimeMax}
                        fieldName="btnDownTimeMax"
                        type="number"
                        onChange={updateData}
                        min={1}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                </div>

                <div className="four-fields">
                    <CreatableSelectWithLabel
                        options={TAGS_PWA}
                        label="Теги"
                        fieldName="tags"
                        value={data.tags}
                        onChange={updateData}
                    />
                </div>

                <Accordion title="Контакты" className="filed-block">
                    <div className="four-fields">
                        <InputWithLabel
                            label="Сайт"
                            value={data.contWebsite}
                            fieldName="contWebsite"
                            onChange={updateData}
                        />
                        <InputWithLabel
                            label="E-mail"
                            value={data.contEmail}
                            fieldName="contEmail"
                            onChange={updateData}
                        />
                        <InputWithLabel
                            label="Адрес"
                            value={data.contAddress}
                            fieldName="contAddress"
                            onChange={updateData}
                        />
                        <InputWithLabel
                            label="Политика"
                            value={data.contPrivacy}
                            fieldName="contPrivacy"
                            onChange={updateData}
                        />
                    </div>
                </Accordion>
            </div>
        </div>
    );
};

export default Description;
