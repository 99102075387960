import ImageUploader from "../../components/UI/ImageUploader";
import InputWithLabel from "../../components/UI/InputWithLabel";
import { formatRating, getResponseErrorsList } from "../../util/util";
import TextareaWithLabel from "../../components/UI/TextareaWithLabel";
import Button from "../../components/UI/Button";
import SelectWithLabel from "../../components/UI/SelectWithLabel";
import {
    ANSWER_OPTIONS,
    DATE_SELECT_OPTIONS,
    DISALLOWED_NUMBER_SYMBOLS,
} from "../../constants/pwa";
import { ImageType } from "../../common/commonTypes";
import ImageWithModal from "../../components/UI/ImageWithModal";
import { useState } from "react";
import Loader from "../../components/UI/Loader";

const Comment = ({
    commentData,
    update,
    deleteComment,
    duplicateComment,
    createErrorMessage,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        avatar,
        auth,
        comment,
        date,
        like,
        rating,
        resComment,
        answerType,
    } = commentData;

    const updateComment = (key, value) => {
        console.log(commentData);
        const commentCopy = { ...commentData };
        commentCopy[key] = value;

        update(commentCopy);
    };

    const handlerAnswerTypeChange = async (filedName, newValue) => {
        const commentCopy = {
            ...commentData,
            answerType: newValue,
            resComment: "",
        };

        update(commentCopy);
        if (newValue === "absent" || newValue === "custom") {
            return;
        }
        setIsLoading(true);
        try {
            setTimeout(() => {
                commentCopy.resComment =
                    "Thank you for your review, your opinion is very important to us! We are glad to see that you like our offers. If you have any questions, please contact support.";
                update(commentCopy);
                setIsLoading(false);
            }, 1000);
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }
    };

    return (
        <div className="comment">
            <div className="author-block">
                <div className="">
                    {avatar ? (
                        <ImageWithModal
                            src={`/images/${avatar}`}
                            onDelete={() => updateComment("avatar", "")}
                        />
                    ) : (
                        <ImageUploader
                            label="Иконка"
                            onChange={updateComment}
                            fieldName="avatar"
                            imageType={ImageType.AVATAR}
                        />
                    )}
                </div>

                <div className="filed-block two-fields">
                    <InputWithLabel
                        label="Автор"
                        value={auth}
                        fieldName="auth"
                        onChange={updateComment}
                    />
                    <SelectWithLabel
                        options={DATE_SELECT_OPTIONS}
                        value={date}
                        onChange={updateComment}
                        label="Дата"
                        fieldName="date"
                    />
                </div>

                <div className="filed-block two-fields">
                    <InputWithLabel
                        label="Рейтинг"
                        value={rating}
                        min={1}
                        max={5}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                        fieldName="rating"
                        onChange={updateComment}
                        onBlur={() =>
                            updateComment("rating", formatRating(+rating))
                        }
                        type="number"
                    />
                    <div>
                        <InputWithLabel
                            label="Лайки"
                            min={1}
                            max={5000}
                            disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                            value={like}
                            fieldName="like"
                            onChange={updateComment}
                            type="number"
                        />
                    </div>
                </div>
                <div className="filed-block">
                    <TextareaWithLabel
                        label="Комментарий"
                        value={comment}
                        onChange={updateComment}
                        fieldName="comment"
                    />
                </div>
            </div>
            <div className="answer-block">
                <div>
                    <div className="two-fields">
                        <SelectWithLabel
                            fieldName="answerType"
                            value={answerType}
                            options={ANSWER_OPTIONS}
                            label="Комментарий ответа"
                            onChange={handlerAnswerTypeChange}
                        />
                    </div>
                    {answerType !== "absent" && (
                        <div
                            className="filed-block"
                            style={{ position: "relative" }}
                        >
                            <TextareaWithLabel
                                value={resComment}
                                onChange={updateComment}
                                fieldName="resComment"
                                disabled={isLoading}
                            />
                            {isLoading && <Loader />}
                        </div>
                    )}
                </div>
                <div className="actions-block">
                    <Button
                        title="Дублировать"
                        size="small"
                        withoutRounded
                        variant="warning"
                        onClick={() => duplicateComment(commentData)}
                    />
                    <Button
                        title="Удалить"
                        variant="error"
                        withoutRounded
                        size="small"
                        onClick={deleteComment}
                    />
                </div>
            </div>
        </div>
    );
};

export default Comment;
