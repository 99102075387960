import React, { useState, useEffect, useId } from 'react';
import Select from 'react-select';
import {GameReleaseState, StoreID} from "../../common/commonTypes";
import Button from '../../components/UI/Button';
import CloseIcon from '../../components/icons/CloseIcon'
import Textarea from '../../components/UI/Textarea';

const UNIVERSAL_REDIRECT_APP_ID = 1;

const SLIDER_STEP_COUNT = 10000;
const MIN_STEP = 1 / SLIDER_STEP_COUNT;
const EPSILON = 1e-9;

const roundWeigth = w => Math.round(w * SLIDER_STEP_COUNT) / SLIDER_STEP_COUNT;
const clampWeight = w => {
    if (w < EPSILON) w = 0.0;
    if (w > 1.0 - EPSILON) w = 1.0;
    return w;
}

const TrafficDistribution = ({ defaultDistribution, onChange, landingTemplates }) => {
    const [distributions, setDistributions] = useState([]);
    const id = useId();

    useEffect(() => {
        if (distributions.length === 0) {
            const initialDistributions = (defaultDistribution && defaultDistribution.length>0) ? defaultDistribution.map((dist) => (
                { app_id: dist.id ? dist.id : UNIVERSAL_REDIRECT_APP_ID, weight: roundWeigth(dist.weight),
                    ...parseTarget(dist.target) }
            )) : [{app_id: UNIVERSAL_REDIRECT_APP_ID, weight: 1.0, target: ''}];
            setDistributions(initialDistributions);
        }
    }, [defaultDistribution]);

    const updateDistribution = (index, changeCallback, shouldRecalculateWeights) => {
        let newDistributions = [...distributions];
        changeCallback(newDistributions[index]);
        if (shouldRecalculateWeights) newDistributions = recalculateWeights(newDistributions, index);
        setDistributions(newDistributions);
    }

    const handleTargetLinkChange = (value, index) => updateDistribution(index, dist => {
        dist.targetLink = value;
    });
    const handleWeightChange = (value, index) => updateDistribution(index, dist => {
        dist.weight = clampWeight(roundWeigth(value));
    }, true);
    const handleLandingChange = (value, index) => updateDistribution(index, dist => {
        dist.landing = value;
    });
    const handleIsLandingChange = (value, index) => updateDistribution(index, dist => {
        dist.isLanding = value;
        if (!dist.landing && landingTemplates && landingTemplates.length > 0) dist.landing = landingTemplates[0];
    });

    const recalculateWeights = (distributions, changedIndex) => {
        const totalWeight = distributions.reduce((sum, dist) => sum + dist.weight, 0);

        if (totalWeight === 1) {
            return distributions;
        }

        if (distributions.length === 1 && changedIndex >= 0) {
            distributions[changedIndex].weight = 1;
            return distributions;
        }

        const remainingWeight = 1 - distributions.reduce((sum, dist) => sum + dist.weight, 0);
        const changeDirection = Math.sign(remainingWeight);
        const canChangeNextDists = distributions.reduce((_canChange, dist, i) => (i > changedIndex &&
            (_canChange || changeDirection < 0 && dist.weight > EPSILON || changeDirection > 0 && dist.weight < (1.0 - EPSILON))), false);

        let canChange;
        if (canChangeNextDists)
            // ми можемо автоматом крутити наступні повзунки після поточного, не збиваючи попередні значення
            canChange = (index) => index > changedIndex || (changedIndex === distributions.length-1) && index !== changedIndex;
        else
            // в цьому напрямку нема можливості крутити наступні, і доведеться автоматом просто крутити всі інші
            canChange = (index) => index !== changedIndex;

        const lockedWeights = distributions.filter((_, i) => !canChange(i));
        const desireRemainingWeight = lockedWeights.reduce((sum, dist) => sum - dist.weight, 1);
        const otherWeights = distributions.filter((_, i) => canChange(i));
        const otherWeightsSum = otherWeights.reduce((sum, dist) => sum + dist.weight, 0);

        const finalGrooming = (distributions) => {
            let remainingWeight = 1 - distributions.reduce((sum, dist) => sum + dist.weight, 0);
            if ( remainingWeight > -EPSILON && remainingWeight < EPSILON ) {
                return distributions;
            }
            const sign = Math.sign(remainingWeight);
            return distributions.map((dist, i) => {
                if (canChange(i) && ( sign > 0 && remainingWeight > (MIN_STEP - EPSILON)
                    || sign < 0 && remainingWeight < (-MIN_STEP + EPSILON) ))
                {
                    let newWeight = clampWeight(roundWeigth(dist.weight + sign * MIN_STEP));
                    remainingWeight = remainingWeight - (newWeight - dist.weight);
                    return {...dist, weight: newWeight};
                } else {
                    return dist;
                }
            });
        }

        if (otherWeightsSum === 0) {
            return finalGrooming(distributions.map((dist, i) =>
                canChange(i) ? { ...dist, weight: clampWeight(roundWeigth(desireRemainingWeight / otherWeights.length)) } : dist
            ));
        }

        return finalGrooming(distributions.map((dist, i) =>
            canChange(i) ? { ...dist, weight: clampWeight(roundWeigth((dist.weight / otherWeightsSum) * desireRemainingWeight)) } : dist
        ));
    };


    const handleAddApp = () => {
        setDistributions([...distributions, { app_id: UNIVERSAL_REDIRECT_APP_ID, weight: distributions.length === 0 ? 1 : 0 }]);
    };

    const handleRemoveApp = (index) => {
        const newDistributions = recalculateWeights(distributions.filter((_, i) => i !== index), -1);
        setDistributions(newDistributions);
    };

    useEffect(() => {
        onChange(
            distributions
                .filter((dist) => dist.app_id !== null)
                .map((dist) => ({
                    app_id: dist.app_id,
                    weight: dist.weight,
                    target: composeTarget(dist)
                }))
        );
    }, [distributions]);

    const selectedAppIds = distributions.map((dist) => dist.app_id);

    const parseTarget = (target) => {
        let targetLink = '';
        let landing = '';
        if (target) {
            try {
                const targetData = JSON.parse(target);
                targetLink = targetData.link;
                if (targetData.landing) {
                    landing = targetData.landing
                }
            } catch (e) {
                targetLink = target;
            }
        }
        return {isLanding: !!landing, landing, targetLink};
    }

    const composeTarget = (dist) => JSON.stringify({
        landing: (dist.isLanding && dist.landing) ? dist.landing : '',
        link: dist.targetLink
    });

    return (
        <div className='traffic-distribution'>
            {/*<h3>Total : {distributions.reduce((sum, dist) => sum + dist.weight, 0)}</h3>*/}
            {distributions.map((dist, index) =>
                <div className={`traffic-distribution-item  ${distributions.length > 1 ? 'with-range' : ''}`} key={index}>
                    <div className={'traffic-distribution-item-content' + (distributions.length > 1 ? ' extended' : '')}>
                        <div className='sub_block'>
                            <div className='sub_block_2'>
                                <div className='nowrap'>Тип распределения:</div><select required>
                                    <option key={0} value={0}>По линке</option>
                                </select>
                            </div>
                            <div className='sub_block_2'>
                                <input id={`dist_${id}_${index}_isLanding`}
                                    className="aaa"
                                    type="checkbox"
                                    checked={dist.isLanding}
                                    onChange={(e) => handleIsLandingChange(e.target.checked, index)}
                                />
                                <label htmlFor={`dist_${id}_${index}_isLanding`}>Лендинг:</label>
                                <select required value={dist.landing} disabled={!dist.isLanding}
                                        onChange={(e) => handleLandingChange(e.target.value, index)}>
                                    {landingTemplates && landingTemplates.map(landingTemplate =>
                                        <option key={landingTemplate} value={landingTemplate}>{landingTemplate}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div style={{padding: '2px 0 6px'}}>
                            <div className="tip" style={{padding: '0 0 1px 4px'}}>Целевая ссылка (target link)</div>
                            <div className="tip"></div>
                            <Textarea 
                            style={{resize: "vertical", height: '60px'}}
                            id="targetLink" className="wide"
                            name="targetLink" 
                            maxLength="512" 
                            value={dist.targetLink}
                            onChange={e => handleTargetLinkChange(e.target.value, index)} required/>
                        </div>
                        {distributions.length > 1 && <div className='range-block'>
                            <input
                                type="range"
                                className='form-range'
                                value={dist.weight * 1000}
                                onChange={(e) => handleWeightChange(Number(e.target.value) / 1000, index)}
                                min="0"
                                max={1000}
                                step={10}
                            />
                            <div className='' style={{whiteSpace: "nowrap", marginLeft: '3px'}}><input
                                type="number"
                                value={Math.round(dist.weight * 10000) / 100}
                                onChange={(e) => handleWeightChange(Number(e.target.value) / 100, index)}
                                min={0}
                                max={100}
                            />%</div>
                            <button
                                type="button"
                                onClick={() => handleRemoveApp(index)}
                            >
                                <CloseIcon/>
                            </button>
                        </div>
                        }
                    </div>
                </div>
            )}
            <Button variant='warning' type='button' onClick={handleAddApp} style={{marginLeft: '24px'}} withoutRounded title='Добавить распределение' size='small' />
        {/* <div style={{margin: '0'}}>
            {/*<h3>Total : {distributions.reduce((sum, dist) => sum + dist.weight, 0)}</h3>* /}
            {distributions.map((dist, index) => (
                <div key={index} style={{ display: 'flex', margin: '0 0 10px 0' }}>
                    <div style={{ width: distributions.length > 1 ? '350px' : '100%', flex: 'none', padding: '0' }}>
                        <div className="tip">Целевая ссылка (target link)</div>
                        <div><textarea style={{resize: "vertical", height: '60px'}} id="targetLink" className="wide"
                                       name="targetLink" maxLength="512" value={dist.target}
                                       onChange={e => handleTargetLinkChange(e.target.value, index)} required/></div>
                    </div>
                    {distributions.length > 1 && [
                    <input
                        type="range"
                        value={dist.weight * 1000}
                        onChange={(e) => handleWeightChange(Number(e.target.value) / 1000, index)}
                        min="0"
                        max={1000}
                        step={10}
                        style={{ width: '130px', margin: '0 10px'}}
                    />,
                    <div className='flex-container-centering' style={{whiteSpace: "nowrap", margin: '6px 0'}}><input
                        type="number"
                        value={Math.round(dist.weight * 10000) / 100}
                        onChange={(e) => handleWeightChange(Number(e.target.value) / 100, index)}
                        min={0}
                        max={100}
                        style={{ width: '50px', marginRight: '2px', padding: '3px 2px' }}
                    />%</div>,
                    <div className='flex-container-centering'><button
                        type="button"
                        onClick={() => handleRemoveApp(index)}
                        className={'listSmallButton'}
                        style={{marginBottom: '10px', padding: '7px'}}
                    >
                        <div className="small2Icon crossSmall2Icon"/>
                    </button></div>
                    ]}
                </div>
            ))}
            <button type="button" className="secondary text-and-icon small2" style={{margin: '12px 0 0 24px'}} onClick={handleAddApp}>
                <span className="icon"><i className="plus"/></span>Добавить распределение
            </button> */}
        </div>
    );
};

export default TrafficDistribution;
