const CheckboxWithLabel = ({ label, isChecked, onChange, fieldName }) => (
    <div className="filed-block">
        <label>{label}</label>
        <input
            className="form-check-input"
            type="checkbox"
            checked={isChecked}
            onChange={(e) => onChange(fieldName, e.target.checked)}
        />
    </div>
);

export default CheckboxWithLabel;
