import SelectWithLabel from "../../components/UI/SelectWithLabel";
import CheckboxWithLabel from "../../components/UI/CheckboxWithLabel";
import Loader from "../../components/UI/Loader";
import {
    BUTTONS_TYPE,
    CATEGORIES_PWA,
    REDIRECTS_OPTIONS,
} from "../../constants/pwa";

const Settings = ({ data, updateData, templates }) => {
    if (!templates) return <Loader />;

    return (
        <div className="settings-pwa">
            <div className="four-fields">
                <SelectWithLabel
                    options={templates}
                    value={data.template}
                    label="Шаблон"
                    fieldName="template"
                    onChange={updateData}
                />
            </div>
            <div className="four-fields">
                <SelectWithLabel
                    options={BUTTONS_TYPE}
                    value={data.installBtnVersion}
                    label="Тип кнопки"
                    fieldName="installBtnVersion"
                    onChange={updateData}
                />
            </div>
            <div className="four-fields">
                <SelectWithLabel
                    options={REDIRECTS_OPTIONS}
                    value={data.redirectVersion}
                    label="Тип редиректа"
                    fieldName="redirectVersion"
                    onChange={updateData}
                />
            </div>
            <div className="four-fields">
                <SelectWithLabel
                    label="Категория в Store"
                    value={data.gpCategory}
                    fieldName="gpCategory"
                    onChange={updateData}
                    options={CATEGORIES_PWA}
                />
            </div>
            <div className="four-fields">
                <CheckboxWithLabel
                    label="Показывать верхнее меню"
                    isChecked={data.showHeaderMenu}
                    fieldName="showHeaderMenu"
                    onChange={updateData}
                />
            </div>
        </div>
    );
};

export default Settings;
