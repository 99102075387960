import { useMemo, useState } from "react";
import Select from "react-select";

import Button from "../../components/UI/Button";
import { sortLanguages } from "../../util/util";

const AddLanguageForm = ({ currentLanguage, onSubmit, languagesList }) => {
    const [selectedLanguages, setSelectedLanguages] = useState({
        lngFrom: "",
        lngTo: "",
    });

    const updateLanguages = (key, value) => {
        setSelectedLanguages((prev) => ({ ...prev, [key]: value }));
    };

    const { optionsFrom, optionsNew } = useMemo(() => {
        const optionsFrom = [];
        const optionsNew = [];

        Object.keys(languagesList).forEach((lng) => {
            const item = { label: languagesList[lng], value: lng };
            if (currentLanguage?.[lng]) {
                optionsFrom.push(item);
            } else {
                optionsNew.push(item);
            }
        });

        return {
            optionsFrom: sortLanguages(optionsFrom),
            optionsNew: sortLanguages(optionsNew),
        };
    }, [currentLanguage]);

    return (
        <div className="add-language-form">
            <h2>Добавить язык</h2>
            {optionsFrom.length !== 0 && (
                <div className="filed-block">
                    <label>Взять с</label>
                    <Select
                        className="select"
                        value={{
                            label:
                                languagesList[selectedLanguages.lngFrom] || "",
                        }}
                        onChange={({ value }) =>
                            updateLanguages("lngFrom", value)
                        }
                        options={optionsFrom}
                    />
                </div>
            )}
            <div className="filed-block">
                <label>Новый язык</label>
                <Select
                    className="select"
                    value={{
                        label: languagesList[selectedLanguages.lngTo] || "",
                    }}
                    onChange={({ value }) => updateLanguages("lngTo", value)}
                    options={optionsNew}
                />
            </div>
            <div className="filed-block">
                <Button
                    title="Добавить"
                    withoutRounded
                    isDisabled={!selectedLanguages.lngTo}
                    onClick={() => onSubmit(selectedLanguages)}
                />
            </div>
        </div>
    );
};

export default AddLanguageForm;
