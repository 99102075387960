
function getResponseErrorsList(err) {
    let data = err.response ? err.response.data : null;

    if (!data)
        return {"common": "Неизвесткая ошибка"};

    if (!data.errors)
        return {"common": "Ошибка сервера"};

    if (Array.isArray(data.errors)) {
        return data.errors.reduce((errors, err) => {
            let field = err.param ? err.param : err.path;
            if (!field) field = 'common';
            return {...errors, ...{[field]: err.msg}};
        }, {});
    }

    if (typeof data.errors === 'object')
        return data.errors;

    return {"common": data.errors};
}

function getResponseError(err) {
    let data = err.response ? err.response.data : null;

    if (!data)
        return "Неизвесткая ошибка";

    if (!data.errors)
        return "Ошибка сервера";

    return data.errors;
}

function formatRating(num) {
    if (num < 1) {
        return "1.0";
    }
    if (num > 5) {
        return "5.0";
    }
    return num.toFixed(1);
}

function formatDate(dateString) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function sortLanguages(languages) {
    return languages.sort((a, b) => a.label.localeCompare(b.label, "ru"));
};

function getRandomInt(min, max) {
    const minInt = Math.ceil(min);
    const maxInt = Math.floor(max);
    
    return Math.floor(Math.random() * (maxInt - minInt + 1)) + minInt;
}

export * from '../common/commonUtil';
export {
    getResponseErrorsList,
    getResponseError,
    formatRating,
    formatDate,
    sortLanguages,
    getRandomInt
}
